import axios from "axios";
const axiosInt = axios.create();
axiosInt.defaults.baseURL =
  window.location.host == "localhost:3000"
    ? "http://localhost/mt5/"
    : process.env.REACT_APP_API_HOST;
// axiosInt.defaults.baseURL = '/mt5/';

axiosInt.interceptors.request.use((request) => {
  if (document?.getElementById("loaderParent")) {
    document.getElementById("loaderParent").style.display = "block";
  }
  return request;
});

axiosInt.interceptors.response.use(
  (response) => {
    if (document?.getElementById("loaderParent")) {
      document.getElementById("loaderParent").style.display = "none";
    }
    return response;
  },
  (error) => {
    if (document?.getElementById("loaderParent")) {
      document.getElementById("loaderParent").style.display = "none";
    }
    return Promise.reject(
      (error.response && error.response.data) || "There is an error!"
    );
  }
);

export default axiosInt;
